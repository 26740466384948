<template>
  <div class="products">
    <div class="intro contentWrapper">
      <router-link to="/trackir5">
        <img :src="imagesUrl + 'products-t5.png'" alt="TrackIR5 Device" />
      </router-link>
      <h1>TrackIR 5</h1>
      <router-link to="/trackir5/" class="btn">Learn More</router-link>
      <router-link to="/downloads/" class="btn">Software</router-link>
    </div>
    <div class="dimBG">
      <two-wide>
        <div class="imgWrapper">
          <router-link to="/accessories/"
            ><img :src="imagesUrl + 'trackclip-pro-on-headphones.png'" alt="Track Clip Pro"
          /></router-link>
        </div>
        <div class="copyWrapper">
          <h2>Accessories</h2>
          <router-link to="/accessories/" class="btn">Shop Now</router-link>
        </div>
      </two-wide>
    </div>
    <div class="dealers">
      <div class="contentWrapper">
        <h2>Dealers</h2>
        <router-link to="/dealers/" class="btn">Find a Dealer</router-link>
      </div>
      <!--END contentWrapper -->
    </div>
    <!--END dealers -->
  </div>
  <!-- END products -->
</template>
<script>
import { IMAGES_URL } from "@/constants/";
import TwoWide from "@/components/Layouts/TwoWide.vue";
export default {
  name: "products",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  components: {
    TwoWide,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.products {
  padding: 85px 0 0px;
}
.twoWide {
  padding: 70px 0 25px;
}
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 39px;
  img {
    width: 583px;
    height: auto;
    margin: 0px 12px 15px 12px;
  }
  h1 {
    margin-bottom: 4px;
  }
  .btn {
    width: 204px;
    margin: 10px 0px 6px;
    box-sizing: border-box;
  }
}
.contentWrapper {
  text-align: center;
}
.copyWrapper {
  padding-top: 15px;
  padding-right: 57px;
  text-align: center;
  background-color: transparent;

  h2 {
    margin-bottom: 25px;
  }
}
.imgWrapper {
  img {
    height: 270px;
    width: auto;
  }
}
.dealers {
  height: 483px;
  background-position: 50% 63px;
  background-size: 877px 357px;
  background-image: url($images-url + "dealers-map.png");
  background-repeat: no-repeat;
  padding: 70px 0;
  box-sizing: border-box;

  .contentWrapper {
    padding-top: 85px;
  }
  h2 {
    margin-bottom: 25px;
  }
}
</style>
